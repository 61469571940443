/**
 * 规则
 */
var verification = {
    //验证密码
    validatePass: (rule, value, callback) => {
        if (!value) {
            callback(new Error("请再次输入密码"));
        } else if (value !== verification.self.formData.password) {
            callback(new Error("两次输入密码不一致!"));
        } else {
            callback();
        }
    },
    //验证手机
    checkPhone: (rule, value, callback) => {
        const phoneReg = /^1[3-9][0-9]{9}$/;
        if (!value) {
            return callback(new Error("电话号码不能为空"));
        }
        if (!Number.isInteger(+value)) {
            callback(new Error("请输入数字值"));
        } else {
            if (phoneReg.test(value)) {
                callback();
            } else {
                callback(new Error("电话号码格式不正确"));
            }
        }
    },
    //验证用户
    checkUser: (rule, value, callback) => {
        const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
        if (!value) {
            return callback(new Error("用户名不能为空"));
        }
        if (name.test(value)) {
            callback();
        } else {
            callback(new Error("用户名为6-20的字母和数字"));
        }
    },
    //验证密码
    checkPassWord: (rule, value, callback) => {
        const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
        if (!value) {
            return callback(new Error("密码不能为空"));
        }
        if (name.test(value)) {
            callback();
        } else {
            callback(new Error("密码为6-20的字母和数字"));
        }
    },
    //验证营业执照
    checkBusinessLicense: (rule, value, callback) => {
        var reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9ABCDEFGHJKLMNPQRTUWXY])$/;
        if (!value) {
            return callback(new Error("请输入统一社会信用代码"));
        } else if (!reg.test(value)) {
            callback(new Error("请输入正确的统一社会信用代码"));
        } else if (!verification.self.formData.businessLicensePic) {
            callback(new Error("请上传统一社会信用代码图片"));
        } else {
            callback();
        }
    },
    //验证许可证
    checkTouristBusinessLicenseNumber: (rule, value, callback) => {
        if (!value) {
            return callback(new Error("请输入许可证号"));
        } else if (!verification.self.formData.touristBusinessLicense) {
            callback(new Error("请上传许可证照"));
        } else {
            callback();
        }
    },
    //验证身份证
    checkIdCard: (rule, value, callback) => {
        if (!value) {
            return callback(new Error("请上传身份证照片"));
        } else {
            callback();
        }
    },

    checkDomesticWorkday: (rule, value, callback) => {
        if (!value) {
            return callback(new Error("请选择国内出票"));
        } else {
            callback();
        }
    },
    checkTravelBusinessLicense: (rule, value, callback) => {
        if (!value) {
            return callback(new Error("请输入旅游经营许可证"));
        } else if (!verification.self.formData.travelBusinessLicensePic) {
            callback(new Error("请上传旅游经营许可证"));
        } else {
            callback();
        }
    },
    checkGuarantee: (rule, value, callback) => {
        if (!verification.self.formData.guarantee) {
            return callback(new Error("请上传担保函"));
        } else {
            callback();
        }
    },
    checkCataProve: (rule, value, callback) => {
        if (!verification.self.formData.cataProve) {
            return callback(new Error("请上传CATA认可证书"));
        } else {
            callback();
        }
    },
    checkId: (rule, value, callback) => {
        var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
        if (!value) {
            return callback(new Error("请输入身份证号"));
        } else if (!regIdCard.test(value)) {
            callback(new Error("请输入正确的身份证号"));
        } else {
            callback();
        }
    },
    isEmail: (rule, value, callback) => {
        const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        if (!value) {
            return callback(new Error("请输入邮箱"));
        } else if (!reg.test(value)) {
            callback(new Error("输入邮箱格式不正确！"));
        } else {
            callback()
        }
    },
    verifySpace: (value) => {
        let reg = /(^\s+)|(\s+$)/g
        if (reg.test(value)) {
            return new Error("请勿输入空格")
        } else {
            return
        }
    }
}
/**
 * 
 * @param {15位营业执照} ints 
 */
function businessLicense15(ints) {
    let ti = 0;
    let si = 0;// pi|11+ti
    let cj = 0;// （si||10==0？10：si||10）*2
    let pj = 10;// pj=cj|11==0?10:cj|11
    let lastNum = '';
    for (let i = 0; i < ints.length; i++) {
        ti = parseInt(ints[i]);
        si = pj + ti;
        cj = (0 == si % 10 ? 10 : si % 10) * 2;
        pj = cj % 11;
        if (i == ints.length - 1) {
            //lastNum =(1 - pj < 0 ? 11 - pj : 1 - pj) % 10;
            lastNum = si % 10
        }
    }
    if (lastNum == 1) {
        return true;
    } else {
        return false
    }
}
export default verification