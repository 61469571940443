import {
  queryTermsList,
  getHotelNightlyRates,
  createOrder,
  getPricesByTimePeriod,
  getReserveHotelInfo,
  getReserveAndCancelAndPromotionInfo,
  getCountryList,
} from "@/lib/data-service/hotel";
import { STATUS } from "@/lib/deprecated/utils/status";
import { getSession } from "@/lib/deprecated/utils/caching";
import verification from "@/lib/deprecated/utils/verification/1.0.0";
const SUCCESS_CODE = STATUS.success;
export default {
  name: "hotelOrder",
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      if (!Number.isInteger(+value)) {
        callback(new Error("请输入电话"));
      } else {
        if (phoneReg.test(value)) {
          callback();
        } else {
          callback(new Error("电话号码格式不正确"));
        }
      }
    };
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //
        },
      },
      // img:require('https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/src/assets/hotel.jpg'),
      img:
        "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/src/assets/hotel.jpg",
      formData: {
        stayNights: 1,
        latestArrivalTime: "",
        roomNum: 1,
      },
      nationalityText: "",
      orderPersonList: [
        {
          lastName: "",
          firstName: "",
          nationality: "",
          gender: 1,
          certificatesType: 1,
          certificatesNumber: "",
        },
      ], //入驻人信息
      gender: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
      certificatesType: [
        {
          value: 1,
          label: "身份证",
        },
        {
          value: 2,
          label: "护照",
        },
        {
          value: 3,
          label: "军官证",
        },
        {
          value: 4,
          label: "学生证",
        },
        {
          value: 5,
          label: "其他",
        },
      ],
      roomList: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
      ], //房间数量
      arriveTime: "", //到达时间
      checkInHotel: {}, //入驻酒店
      formDataUser: {}, //联系人信息
      rules: {
        //验证
        buyerLinkman: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { validator: verification.isEnglishName },
        ],
        buyerPhone: [
          { validator: checkPhone, trigger: "blur", required: true },
        ],
      },
      hotelInfo: {}, //酒店信息
      homeInfo: {},
      roomPackage: {},
      home: [], //房费列表
      orderAmount: 0,
      giftBag: {}, //礼包信息
      OrderInformation: {}, //下单须知
      fristStatu: false, //首次加载
      roomViewList: {},
      nationalityList: [],

      submitDisabled: false,
    };
  },
  watch: {
    "formData.roomNum"(newVal, oldVal) {
      if (newVal > oldVal) {
        for (let i = 0; i < newVal - oldVal; i++) {
          this.orderPersonList.push({ certificatesNumber: '', certificatesType: 1, firstName: '', gender: '', lastName: '', nationality: '' });
        }
      } else {
        this.orderPersonList.splice(newVal, oldVal - newVal);
      }
    },
  },
  created() {
    let data = getSession("HOTELORDERDATA");
    let time = getSession("HOTELORDERTIME");
    if (data) {
      let HotelTime = [
        this.$moment(time.checkInTime)._d,
        this.$moment(time.checkOutTime)._d,
      ];
      this.checkInHotel = HotelTime;
      this.hotelInfo = data.hotelList;
      console.log('hotelInfohotelInfohotelInfohotelInfo:', this.hotelInfo);

      this.homeInfo = data.hotel;
      this.roomPackage = data.roomPackage;
      this.getDateList();
      this._getCountryList();
    }
  },
  methods: {
    name_input(value, index, key) {
      this.orderPersonList[index][key] = value.replace(/^[^(A-Za-z\u4e00-\u9fa5)]+$/g, '');
    },


    setTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.checkInHotel) {
            if (_this.checkInHotel[0]) {
              return (
                time.getTime() < Date.now() - 1000 * 3600 * 24 ||
                time.getTime() >
                _this.$moment(_this.checkInHotel[0]).subtract("days", -30)._d
              );
            }
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        },
      };
    },
    verifiy(value = "", name, callback) {
      let rules = {
        idCard: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
        other: /^[a-zA-Z0-9]*$/,
      };
      let verifiyFn = () => {
        if (!rules[name]) return console.error(name + " error");
        if (!rules[name].test(value)) {
          typeof callback === "function" && callback();
        }
      };
      verifiyFn();
    },
    checkCertificatesNumber(val, index, name, cardType) {
      if (cardType === 1) {
        this.verifiy(val, "idCard", () => {
          this.$set(this.orderPersonList[index], name, "");
          this.$message({ type: "warning", message: "身份证输入有误！" });
        });
      } else {
        this.verifiy(val, "other", () => {
          this.$set(this.orderPersonList[index], name, "");
          this.$message({ type: "warning", message: "证件输入有误！" });
        });
      }
    },
    _getCountryList() {
      getCountryList().then((res) => {
        console.log(res);
        this.nationalityList = res.countries;
      });
    },
    queryNationality(queryString, cb) {
      var restaurants = this.nationalityList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.countryCode
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) !== -1 ||
          restaurant.cname.toLowerCase().indexOf(queryString.toLowerCase()) !==
          -1
        );
      };
    },
    selectnationality(res, index, name) {
      // this.nationalityText = res.cname;
      this.$set(this.orderPersonList[index], "nationalityText", res.cname);
      this.$set(this.orderPersonList[index], name, res.countryCode);
    },
    settlingTime() {
      let time = this.checkInHotel;
      if (!time) return;
      let day = this.formData.stayNights;
      day = this.datedifference(time[0], time[1]);
      if (this.formData.stayNights > 30) {
        this.formData.stayNights = day;
        return this.$message({ type: "warning", message: "最多可预定30天" });
      }
      this.getDateList();
    },
    //房间数量
    getRoomNum(val) {
      let newMoney = this.orderAmount * val;
      this.formData.orderAmount = newMoney.toFixed(2);
    },
    //增加入住人
    addCheckIn(index) {
      let data = {
        lastName: "",
        firstName: "",
        nationality: "",
        gender: 1,
        certificatesType: 1,
        certificatesNumber: "",
      };
      let maximize = this.homeInfo.maximize;
      // if (this.orderPersonList.length >= this.formData.roomNum * maximize) {
      if (this.orderPersonList.length >= this.formData.roomNum) {
        return this.$message({
          message: `提示：一间房间只需添加${1}人`,
          type: "warning",
        });
      }
      this.orderPersonList.push(data);
    },
    //删除入住人
    removeCheckOut(index) {
      if (this.orderPersonList.length == 1) return;
      this.orderPersonList.splice(index, 1);
    },
    beforeunloadHandler(e) {
      e.returnValue = "确定要关闭窗口吗？";
      setTimeout(function () {
        setTimeout(function () { }, 50);
      }, 50);
    },
    //支付订单
    submitNext() {
      let orderPersonList = this.orderPersonList.every((item) => {
        for (let x in item) {
          if (!item[x]) {
            this.$message({ type: "warning", message: "请完善入住人信息" });
            return false;
          }
        }
        return true;
      });
      function setSpacesArr(arr = []) {
        Array.isArray(arr)
          ? arr.map((item) => {
            if (item instanceof Object) {
              for (let i in item) {
                typeof item[i] === "string"
                  ? (item[i] = item[i].replace(/\s+/g, ""))
                  : "";
              }
            } else {
              typeof item === "string"
                ? (item = item.replace(/\s+/g, ""))
                : "";
            }
          })
          : "";
      }
      function setSpacesObj(obj = {}) {
        if (obj instanceof Object) {
          for (let i in obj) {
            typeof obj[i] === "string"
              ? (obj[i] = obj[i].replace(/\s+/g, ""))
              : "";
          }
        }
      }
      setSpacesArr(this.orderPersonList);
      setSpacesObj(this.formData);
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.formData));
          if (!data.stayNights) {
            this.$message({ type: "warning", message: "请选择入住时间" });
            return;
          }
          if (!orderPersonList) return;
          if (!this.arriveTime) {
            this.$message({ type: "warning", message: "请选择最晚到店日期" });
            return;
          }
          data.keyId = this.roomPackage.keyId;
          data.hotelId = this.$route.query.hotelId;
          data.checkInDate = this.$moment(this.checkInHotel[0]).format(
            "YYYY-MM-DD"
          );
          data.checkOutDate = this.$moment(this.checkInHotel[1]).format(
            "YYYY-MM-DD"
          );
          let home = JSON.parse(JSON.stringify(this.home));
          let homeList = home[0];

          homeList.distributorPrice = homeList.supplierPrice;
          homeList.cose = homeList.platformPrice;
          data.orderPersonList = JSON.stringify(this.orderPersonList);
          data.latestArrivalTime = this.arriveTime;
          data.hotelNameCn = this.hotelInfo.hotelNameCn;
          data.roomTypeCn = this.roomPackage.name;
          data.roomTypeId = this.roomPackage.roomTypeId;
          data.subPrice = home.subPrice;
          data.nightlyRateList = JSON.stringify(home);
          data.orderFromType = this.homeInfo.hotelFrom;
          data.thirdpartyHotelId = this.roomViewList.thirdpartyHotelId;
          data.bookingRule = JSON.stringify(this.OrderInformation.bookingRule);
          data.refundRule = JSON.stringify(this.OrderInformation.refundRule);
          data.orderPromotionList = [];
          data.rateTypeCn = this.roomViewList.ratePlanViewList[0].rateTypeCn;
          data.rateTypeId = this.roomViewList.ratePlanViewList[0].rateTypeId;
          data.imageUrl = this.hotelInfo.imageUrl;
          data.ifInvoice = this.roomViewList.ratePlanViewList[0].ifInvoice;

          setSpacesObj(data);
          this.submitDisabled = true;

          createOrder(data).then((res) => {
            console.log(res);
            if (res.createOrderResult.createOrder.orderStatus == 0) {
              this.$router.push({
                name: "distributor-hotel-front-hotel-pay",
                query: { orderNo: res.createOrderResult.createOrder.orderCode },
              });
            } else {
              this.$message({ type: "warning", message: "订单创建失败！" });
            }
          }).finally(() => {
            this.submitDisabled = true;
          });
        } else {
          // this.$message({type:'warning',message:'请完善订单信息'})
          return false;
        }
      });
    },
    //酒店日历金额
    getDateList(val) {
      let data = {
        checkInDate: this.$moment(this.checkInHotel[0]).format("YYYY-MM-DD"),
        checkOutDate: this.$moment(this.checkInHotel[1]).format("YYYY-MM-DD"),
        keyId: this.roomPackage.keyId,
        hotelId: this.$route.query.hotelId,
        roomTypeId: this.roomPackage.roomTypeId,
      };
      // console.log(data)
      getPricesByTimePeriod(data).then((res) => {
        if (res.code == SUCCESS_CODE) {
          console.log(res);
          this.roomViewList = res.roomViewList[0];
          this.home = res.roomViewList[0].ratePlanViewList[0].nightlyRateList;
          this.formData.coseList =
            res.roomViewList[0].ratePlanViewList[0].coseList;
          this.formData.stayNights = this.home.length;
          let s = 0;
          // this.home.some(item => {
          //   if (item.platformPrice == 0) {
          //     let endTime = this.$moment(item.date)._d;
          //     this.$set(this.checkInHotel, 1, endTime);
          //     // console.log(this.checkInHotel[0].endTime)
          //     this.$message({
          //       type: "warning",
          //       message: "最高可预定到" + item.date + "之前！"
          //     });
          //     return true;
          //   }
          // });
          if (
            this.datedifference(data.checkOutDate, data.checkInDate) >
            this.formData.stayNights
          ) {
            let endDate = this.home[this.home.length - 1].date;
            endDate = this.$moment(endDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
            this.$set(this.checkInHotel, 1, endDate);
            this.$message({
              type: "warning",
              message: "最高可预定到" + endDate + "之前！",
            });
          }
          this.home = this.home.filter((item) => {
            s += item.platformPrice;
            return item.platformPrice > 0;
          });
          this.formData.orderAmount = this.orderAmount = s.toFixed(2);
          this.getRoomNum(this.formData.roomNum);

          // this.getGiftBag({refundRuleId:res.refundRuleId,bookingRuleId:res.bookingRuleId})
          this.giftBag = res.promotion;
          this.OrderInformation.refundRule = res.refundRule;
          this.OrderInformation.bookingRule = res.bookingRule;
          let bookingRule = this.OrderInformation.bookingRule;
          this.OrderInformation.bookingRule.minDatetime = bookingRule.minDatetime
            ? this.$moment(bookingRule.minDatetime).format("YYYY-MM-DD")
            : "";
          this.OrderInformation.bookingRule.maxDatetime = bookingRule.maxDatetime
            ? this.$moment(bookingRule.maxDatetime).format("YYYY-MM-DD")
            : "";
        }
      });
    },

    //酒店礼包信息
    getGiftBag(val) {
      let data = {
        keyId: this.roomPackage.keyId,
        bookingRuleId: val.bookingRuleId
          ? val.bookingRuleId
          : this.roomPackage.bookingRuleId,
        refundRuleId: val.refundRuleId
          ? val.refundRuleId
          : this.roomPackage.refundRuleId,
        checkInDate: this.$moment(this.checkInHotel[0]).format("YYYY-MM-DD"),
        checkOutDate: this.$moment(this.checkInHotel[1]).format("YYYY-MM-DD"),
        hotelId: this.$route.query.hotelId,
      };
      getReserveAndCancelAndPromotionInfo(data).then((res) => {
        console.log("礼包", res);
        if (res.code == SUCCESS_CODE) {
          this.giftBag = res.promotion;
          this.OrderInformation.refundRule = res.refundRule;
          this.OrderInformation.bookingRule = res.bookingRule;
          this.$forceUpdate();
        }
      });
    },
    //计算天数
    datedifference(sDate1, sDate2) {
      var dateSpan, tempDate, iDays;
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
  },
};
